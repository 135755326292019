<template>
  <main>
    <div class="container-fluid">
      <div class="row" style="min-height: calc(100vh - 200px)">
        <div class="col-md col-sm align-self-center">
          <div class="col-md-8 col-sm-12 mx-auto py-5">
            <div class="text-center mb-5">
              <h1 class="text-info mb-2">
                Connexion à votre espace service public de l'emploi
              </h1>
              <!-- <h6 class="text-muted"></router-link></h6> -->
            </div>
            <form
              @submit.prevent="onSubmit"
              class=""
              method="post"
              accept-charset="utf-8"
              action=""
            >
              <div class="form-floating mb-3">
                <input
                  v-model="user.username"
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label for="floatingInput">Adresse email ou Téléphone</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Mot de passe"
                />
                <label for="floatingPassword">Mot de passe</label>
              </div>
              <div class="d-flex justify-content-between mb-4">
                <div class="form-check form-check-inline"></div>
                <router-link
                  class="text-danger"
                  :to="{ name: 'accounts.registration.resetPassword' }"
                  >Mot de passe oublié</router-link
                >
              </div>
              <div class="d-grid gap-2 my-4">
                <button type="submit" class="btn btn-info btn-lg">Connexion</button>
              </div>
            </form>
            <!-- <h6 class="text-muted text-center">Vous n'avez pas un compte entreprise <routerLink :to="{ name: 'accounts.register.ent' }" class="text-success">Inscrivez-vous</routerLink></h6> -->
          </div>
        </div>
        <div class="col-md col-sm-12 p-0">
          <div
            class="h-100 style_bg_img"
            style="background-image: url('/assets/img/entreprise.jpg')"
          >
            <div
              class="style_bg_img_bloc p-lg-5 h-100 d-flex flex-column justify-content-center text-center"
            >
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-sm-8 mx-auto">
                    <h1 class="text-white mb-4 fw-bolder">Service public de l'emploi!</h1>
                    <p class="text-white mb-3">Tous au service de l'emploi.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { authMixin } from "../../mixins/auth-mixin";
import { mapActions } from "vuex";
import { AbilityBuilder, Ability } from "@casl/ability";
import { ABILITY_TOKEN } from "@casl/vue";
import { abilityDefinition } from "../../acsl/define-ability";

export default {
  name: "PageLogin",
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      error: false,
    };
  },
  watch: {
    user: {
      handler() {
        this.error = false;
      },
      deep: true,
    },
  },
  mixins: [authMixin],
  created() {
    this.forceLogout();
  },
  methods: {
    ...mapActions({
      authenticate: "auth/login",
      userRole: "auth/userRole",
    }),
    async onSubmit() {
      this.loading = true;
      try {
        await this.authenticate(this.user);
        this.updateAbility(); // Mettre  à jour les privilèges de l'utilisateur actuel
        this.gotoAfterLogin();
        this.loading = false;
        window.location.reload();
      } catch (error) {
        // })
        if (error) {
          console.error("Authentication error", error);
        }
        this.$toast.error("Login ou mot de passe incorrecte", {
          position: "bottom-right",
          duration: 5000,
        });
        this.loading = false;
        this.error = true;
      }
    },

    gotoAfterLogin() {
      // this.$router.push({ name: `${this.currentUserHome}` })
      this.$router.push({
        name: `espace.common.azolis.home`,
        params: { userRole: this.$userRoleName },
      });
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder(Ability);

      abilityDefinition(can);

      this.$ability.update(rules);
    },
  },
};
</script>

<!-- 
<script>
import { mapActions } from 'vuex'
export default {
  name: 'PageLogin',
  data () {
    return {
      user: {
        username: '',
        password: ''
      },
      error: false
    }
  },
  watch: {
    user: {
      handler(){
        this.error = false
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/login',
      userRole: 'auth/userRole'
    }),
    async onSubmit () {
      this.loading = true
      try {
        await this.authenticate(this.user)
        let redirectTo = this.$route.query.redirect
        if(redirectTo){
          this.$router.push({ path: redirectTo })
        }
        else {
          this.$router.push({ name: 'espace.entreprise.home' })
        }
        this.loading = false
      }
      catch (error) {
        // })
        if (error) {
          console.error('Authentication error', error)
        }
         this.$toast.error("Login ou mot de passe incorrecte", {
            position: 'bottom-right',
            duration: 5000
          })
        this.loading = false
        this.error = true
      }
    },
    gotoRegisterPage () {
      this.$router.push({ name: 'register' })
    }
  }
}
</script> -->
